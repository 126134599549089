
/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
.ant-input-password-icon{
    color: #1890ff !important;
    text-decoration: underline;
    font-size: small;
    font-weight: bold;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.text-blue{
    color: #0079D6;
}
.bg-blue{
   background-color:  #0079D6;
}


.ant-btn-success {
    color: #fff;
    background: #04c330;
    border-color: #04c330;
}